import React from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { LinkedLogo, ExternalLink } from '../../../../components';
import { injectIntl, FormattedMessage } from 'react-intl';

import Field from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionFooter.module.css';

import { replaceUnderscoresWithHyphens } from '../../../../util/string';

// The number of columns (numberOfColumns) affects styling

const GRID_CONFIG = [
  { contentCss: css.contentCol1, gridCss: css.gridCol1 },
  { contentCss: css.contentCol2, gridCss: css.gridCol2 },
  { contentCss: css.contentCol3, gridCss: css.gridCol3 },
  { contentCss: css.contentCol4, gridCss: css.gridCol4 },
];

const getIndex = numberOfColumns => numberOfColumns - 1;

const getContentCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.contentCss : GRID_CONFIG[0].contentCss;
};

const getGridCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.gridCss : GRID_CONFIG[0].gridCss;
};

// Section component that's able to show blocks in multiple different columns (defined by "numberOfColumns" prop)
const SectionFooter = ({ intl, ...props }) => {
  const {
    sectionId,
    className,
    rootClassName,
    numberOfColumns,
    socialMediaLinks,
    slogan,
    appearance,
    copyright,
    options,
  } = props;

  const brandNameOriginal = process.env.REACT_APP_BRAND_NAME;
  const brandName = replaceUnderscoresWithHyphens(brandNameOriginal);

  const blocks = [
    {
      blockId: 'faqAndSupport',
      blockType: 'footerBlock',
      text: {
        content: `- [${intl.formatMessage({
          id: 'SectionFooter.faqSupport',
        })}](/p/faq-support-${brandName})`,
        fieldType: 'markdown',
      },
    },
    {
      blockId: 'terms',
      blockType: 'footerBlock',
      text: {
        content: `- [${intl.formatMessage({
          id: 'SectionFooter.terms',
        })}](/p/terms-of-service-${brandName})`,
        fieldType: 'markdown',
      },
    },
    {
      blockId: 'privacy',
      blockType: 'footerBlock',
      text: {
        content: `- [${intl.formatMessage({
          id: 'SectionFooter.privacy',
        })}](/p/privacy-policy-${brandName})`,
        fieldType: 'markdown',
      },
    },
    {
      blockId: 'imprint',
      blockType: 'footerBlock',
      text: {
        content: `- [${intl.formatMessage({
          id: 'SectionFooter.imprint',
        })}](/p/imprint-${brandName})`,
        fieldType: 'markdown',
      },
    },
  ];

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const linksWithBlockId = socialMediaLinks?.map(sml => {
    return {
      ...sml,
      blockId: sml.link.platform,
    };
  });

  const showSocialMediaLinks = socialMediaLinks?.length > 0;

  // use block builder instead of mapping blocks manually

  return (
    <SectionContainer
      as="footer"
      id={sectionId}
      className={className || css.root}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
      style={{ backgroundColor: '#ececec' }}
    >
      <div className={css.footer}>
        <div className={classNames(css.content, getContentCss(numberOfColumns))}>
          <div className={css.sloganMobile}>
            <Field data={slogan} className={css.slogan} />
          </div>
          <div className={css.detailsInfo}>
            <div className={css.contactInfo}>
              <span>
                <FormattedMessage id="SectionFooter.contact" />
              </span>
              <p className={css.contactInfoTextEmail}>
                <FormattedMessage id="SectionFooter.emailLabel" />:{' '}
                <a
                  className={css.contactInfoTextEmail}
                  href={`mailto:${intl.formatMessage({ id: 'SectionFooter.emailAddress' })}`}
                >
                  {intl.formatMessage({ id: 'SectionFooter.emailAddress' })}
                </a>
              </p>
              {process.env.REACT_APP_SUPPORT_PHONE_NUMBER && (
                <p className={css.contactInfoTextPhone}>
                  <FormattedMessage id="SectionFooter.phoneLabel" />:{' '}
                  {process.env.REACT_APP_SUPPORT_PHONE_NUMBER}
                </p>
              )}
              <p className={css.contactInfoTextOpeningHours}>
                <FormattedMessage id="SectionFooter.operatingHours" />
              </p>
            </div>
            {showSocialMediaLinks ? (
              <div className={css.icons}>
                <BlockBuilder blocks={linksWithBlockId} options={options} />
              </div>
            ) : null}
            <ExternalLink href="https://brandback.shop/" className={css.copyright}>
              <Field data={copyright} />
            </ExternalLink>
          </div>
          <div className={classNames(css.grid, getGridCss(numberOfColumns))}>
            <BlockBuilder blocks={blocks} options={options} />
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  numberOfColumns: 1,
  socialMediaLinks: [],
  slogan: null,
  copyright: null,
  appearance: null,
  blocks: [],
  options: null,
};

SectionFooter.propTypes = {
  intl: object.isRequired,
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  numberOfColumns: number,
  socialMediaLinks: arrayOf(object),
  slogan: object,
  copyright: object,
  appearance: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default injectIntl(SectionFooter);
